import React from "react";

import { Container, Row, Col } from "react-bootstrap";

const Features = () => {
  return (
    <>
      {/* <!-- Features Area --> */}
      <div className="feature-section bg-default-1 pb-4 pb-lg-13 ">
        <Container>
          <Row>
            <Col sm="6" lg="4" className="mb-8">
              <div className="feature-widget-1">
                <div className="media pr-8 pr-lg-7 pr-xl-12">
                  <div className="widget-icon mr-8">
                    <span className="fas fa-magic"></span>
                  </div>
                  <div className="media-body">
                    <h5 className="gr-text-7 mb-7">Automate Product Demos</h5>
                    <p className="gr-text-9">
                      Leverage video content to engage your prospects with an interactive demo of your product with a chatbot leading the conversation.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm="6" lg="4" className="mb-8">
              <div className="feature-widget-1">
                <div className="media pr-8 pr-lg-7 pr-xl-12">
                  <div className="widget-icon mr-8">
                    <span className="fas fa-user-check"></span>
                  </div>
                  <div className="media-body">
                    <h5 className="gr-text-7 mb-7">Easily Qualify Leads</h5>
                    <p className="gr-text-9">
                      Asking questions throughout their demo seems like a conversation, but more importantly gathers information to qualify and convert them later.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm="6" lg="4" className="mb-8">
              <div className="feature-widget-1">
                <div className="media pr-8 pr-lg-7 pr-xl-12">
                  <div className="widget-icon mr-8">
                    <span className="fas fa-sync"></span>
                  </div>
                  <div className="media-body">
                    <h5 className="gr-text-7 mb-7">Sync With CRM</h5>
                    <p className="gr-text-9">
                      All interactions are stored with us and additionally can be synced with your CRM or other marketing software.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Features;
