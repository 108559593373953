import React from "react";
import PropTypes from 'prop-types';
import { Container, Row, Col, Button } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Hero = ({onClickInstantDemo, onClickFreeTrial}) => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "l1/jpg/embedded-widget-browser.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      {/* <!-- Hero Area --> */}
      <div className="pb-11 pb-lg-25 pt-25 pt-lg-29 bg-default-1 position-relative">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col lg="6" md="9" className="order-lg-2">
              <div
                className="hero-img position-relative"
                data-aos="fade-left"
                data-aos-duration="750"
                data-aos-delay="500"
              >
                <Img
                  className="img-fluid rounded-10"
                  alt="Embedded Instant Demo Widget"
                  fluid={data.heroImage.childImageSharp.fluid}
                />
              </div>
              <div className="hero-sm-card-1">
                <div
                  className="px-7 py-6 d-flex bg-gray-3 shadow-10 rounded-15 w-xs-75 w-lg-76 w-xl-68 hero-card-1-animation"
                  id="an-item-1p1"
                >
                  <p className="gr-text-11 mb-0 text-mirage-2">
                    “Hi! I'll be walking you through an Instant Demo of our product! What's your name?”
                  </p>
                </div>
              </div>
              <div className="animation-item">
                <div
                  className="px-7 py-6 d-flex bg-blue shadow-9 rounded-15 hero-sm-card-2 hero-card-2-animation"
                  id="an-item-1p2"
                >
                  <p className="gr-text-11 mb-0 text-white">
                    John
                  </p>
                </div>
              </div>
            </Col>
            <Col
              lg="6"
              md="9"
              className="order-lg-1 mt-9 mt-lg-0"
              data-aos="fade-right"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="hero-content">
                <h1 className="gr-text-3 mb-8">
                  Qualified leads are waiting for demos
                </h1>
                <p className="gr-text-8">
                  Create an interactive product demo to qualify and convert leads on your website, drive product engagement through outbound sales, or completely automate the demo experience.
                </p>
                <div className="hero-btns d-flex flex-column flex-md-row mt-11">
                  <Button variant="primary" className="with-icon gr-hover-y mb-6 mb-md-0 mr-md-7" onClick={onClickInstantDemo}>
                    Instant Demo
                    <i className="fas fa-arrow-right gr-text-11"></i>
                  </Button>
                  <Button variant="white" className="gr-hover-y" onClick={onClickFreeTrial}>
                    Free Trial
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

Hero.propTypes = {
  onClickInstantDemo: PropTypes.func,
  onClickFreeTrial: PropTypes.func
}

Hero.defaultProps = {
  onClickInstantDemo: () => {},
  onClickFreeTrial: () => {}
}

export default Hero;