import React from "react";
import PropTypes from 'prop-types';
import { Container, Row, Col, Button } from "react-bootstrap";

const CTA = ({onClickInstantDemo}) => {
  return (
    <>
      {/* <!-- CTA Area --> */}
      <div className="cta-section-1 py-12 pt-lg-20 pb-lg-18 border-bottom border-gray-3 ">
        <Container>
          <Row className="align-items-center ">
            <Col lg="6">
              <div className="section-title cta-text pr-lg-5">
                <h2 className="gr-text-5 mb-7">
                  Did we convince you yet?
                </h2>
                <p className="gr-text-8">
                  Get a live demo of our product now, so you can see how it will work for your app.
                </p>
              </div>
            </Col>
            <Col lg="6" xl="5" className="offset-xl-1">
              <div className="cta-btn d-flex flex-column flex-sm-row justify-content-lg-end mt-5 mt-lg-0">
                <Button variant="primary" onClick={onClickInstantDemo} className="with-icon gr-hover-y">
                  Get a live demo
                  <i className="fas fa-arrow-right gr-text-11"></i>
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

CTA.propTypes = {
  onClickInstantDemo: PropTypes.func
}

CTA.defaultProps = {
  onClickInstantDemo: () => {}
}

export default CTA;
