import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Modal, Button, Form, Spinner } from "react-bootstrap";

const SignupModal = (props) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')

  const onChangeEmail = (e) => {
    const { target: { value } } = e;

    setEmail(value)
  }

  const onChangeName = (e) => {
    const { target: { value } } = e;

    setName(value)
  }

  const onSubmit = (e) => {
    e.preventDefault()

    props.onSubmit({name, email})
  }

  const spinner = (
    <Spinner animation="border" role="status" />
  )

  return (
    <Modal show={props.showModal} onHide={props.onClose}>
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            Sign up for a free trial
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formName">
            <Form.Label>Name*</Form.Label>
            <Form.Control type="name" placeholder="Your name" value={name} onChange={onChangeName} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formEmail">
            <Form.Label>Work email*</Form.Label>
            <Form.Control type="email" placeholder="Your email address" name={email} onChange={onChangeEmail} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex flex-column flex-md-row w-100 justify-content-end">
            <Button className="mb-6 mb-md-0 mr-md-4" type="button" variant="white" onClick={props.onClose}>
              Cancel
            </Button>
            <Button type="submit" variant="primary" disabled={!(email && name)}>
              {props.isSubmitting ? spinner : "Sign Up"}
            </Button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

SignupModal.propTypes = {
  onClose: PropTypes.func,
  showModal: PropTypes.bool,
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool
}

SignupModal.defaultProps = {
  showModal: false,
  onClose: () => {},
  onSubmit: () => {},
  isSubmitting: false
}

export default SignupModal;
