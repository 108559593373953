import React from "react";
import PropTypes from 'prop-types';
import { Container, Row, Col } from "react-bootstrap";

const Pricing = ({onClickContactUs}) => {
  return (
    <>
      {/* <!-- Pricing Area --> */}
      <div
        className="pricing-section bg-blackish-blue pt-12 pb-7 pt-lg-22 pb-lg-17"
        id="pricing-section"
      >
        <Container>
          <Row className="justify-content-center">
            <Col xl="7" lg="8" md="10">
              <div className="section-title text-center mb-12 mb-lg-16">
                <h2 className="gr-text-4 mb-7 text-white">
                  Pricing & Plans
                </h2>
                <p className="gr-text-8 px-lg-8 gr-color-white-opacity-7 mb-0">
                  Our pricing grows with your success. Please reach out if you have any questions or just want to say hi!
                </p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg="4" md="6" sm="8" className="mb-9">
              <div className="pricing-card gr-hover-shadow-1 bg-white border rounded-10 text-center pt-9 pb-6 pr-6 pl-6">
                <span className="badge gr-badge text-primary gr-bg-blue-opacity-1 rounded-pill gr-text-12 text-uppercase">Premium</span>
                <h2 className="price-value gr-text-3 font-weight-bold text-blackish-blue line-spacing-none mb-2 mt-8">
                  Custom
                </h2>
                <p className="gr-text-11 mb-7 mb-lg-11 gr-color-blackish-blue-opacity-7">contact us</p>
                <p className="gr-text-9 px-5 gr-color-blackish-blue-opacity-7 mb-0">We can tailor a plan based on your company size and usage that best fits your needs.</p>
                <button
                  onClick={onClickContactUs}
                  className="btn btn-primary gr-hover-y w-100 mt-9 mt-lg-15 gr-text-9"
                >
                  Contact Us
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

Pricing.propTypes = {
  onClickContactUs: PropTypes.func
}

Pricing.defaultProps = {
  onClickContactUs: () => {}
}

export default Pricing;
