import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Faq = () => {
  return (
    <>
      {/* <!-- Faq Area --> */}
      <div className="faq-section pb-12 pb-lg-24 bg-blackish-blue dark-mode-texts">
        <Container>
          <Row>
            <Col md="6" className="mb-9 pr-xl-12 mb-lg-15">
              <div className="single-faq media">
                <div className="count circle-xs bg-green mr-8">
                  <i className="fas fa-question text-white"></i>
                </div>
                <div className="faq-text">
                  <h3 className="faq-title mb-7 gr-text-7 gr-text-color">
                    What if leads want to schedule a live demo?
                  </h3>
                  <p className="gr-text-9 gr-text-color-opacity">
                    We offer a way to schedule a future demo with one of your sales reps directly from chat. This can be triggered by the lead or in your flow.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="6" className="mb-9 pr-xl-12 mb-lg-15">
              <div className="single-faq media">
                <div className="count circle-xs bg-green mr-8">
                  <i className="fas fa-question text-white"></i>
                </div>
                <div className="faq-text">
                  <h3 className="faq-title mb-7 gr-text-7 gr-text-color">
                    What integrations do you support?
                  </h3>
                  <p className="gr-text-9 gr-text-color-opacity">
                    We integrate with Hubspot, Marketo, Salesforce, and various other sales and marketing softwares. If we don't integrate, reach out and we can add it to our roadmap.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="6" className="mb-9 pr-xl-12 mb-lg-15">
              <div className="single-faq media">
                <div className="count circle-xs bg-green mr-8">
                  <i className="fas fa-question text-white"></i>
                </div>
                <div className="faq-text">
                  <h3 className="faq-title mb-7 gr-text-7 gr-text-color">
                    What if a high value lead is on my site right now?
                  </h3>
                  <p className="gr-text-9 gr-text-color-opacity">
                    You can request to be notified immediately if someone selects a specific answer. A user can hop on in real time to join the chat and take over.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="6" className="mb-9 pr-xl-12 mb-lg-15">
              <div className="single-faq media">
                <div className="count circle-xs bg-green mr-8">
                  <i className="fas fa-question text-white"></i>
                </div>
                <div className="faq-text">
                  <h3 className="faq-title mb-7 gr-text-7 gr-text-color">
                    Do you support any other content other than video?
                  </h3>
                  <p className="gr-text-9 gr-text-color-opacity">
                    In addition to supporting videos and pictures, we let you interact with custom APIs to bring dynamic content into the chat.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="6" className="mb-9 pr-xl-12 mb-lg-15">
              <div className="single-faq media">
                <div className="count circle-xs bg-green mr-8">
                  <i className="fas fa-question text-white"></i>
                </div>
                <div className="faq-text">
                  <h3 className="faq-title mb-7 gr-text-7 gr-text-color">
                    What if I want to use my existing form with the product demo?
                  </h3>
                  <p className="gr-text-9 gr-text-color-opacity">
                    An instant demo can be triggered from any front-end event (button, link, form submission). You can even pass in information for your flow based on form field values.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="6" className="mb-9 pr-xl-12 mb-lg-15">
              <div className="single-faq media">
                <div className="count circle-xs bg-green mr-8">
                  <i className="fas fa-question text-white"></i>
                </div>
                <div className="faq-text">
                  <h3 className="faq-title mb-7 gr-text-7 gr-text-color">
                    Why is this better than just using a request demo form or just a demo video?
                  </h3>
                  <p className="gr-text-9 gr-text-color-opacity">
                    Forms deter prospects. Plain videos aren't personalized. An interactive demo lets your prospects see your product, tailored based on their responses, all while gathering qualifying information about them.
                  </p>
                </div>
              </div>
            </Col>
            <div className="col-12 text-center mt-3 ">
              <p className="more-support gr-text-9 gr-text-color">
                Haven’t got your answer?{" "}
                <a href="mailto:support@instantdemo.co" className="support-link text-green">
                  Contact our support now
                </a>
              </p>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Faq;
