import React from "react";
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap";

const Content = ({learnMoreRef}) => {
  const data = useStaticQuery(graphql`
    query {
      contentImage: file(relativePath: { eq: "landing/builder-screenshot.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section2 pt-12 pb-5 pt-lg-19 pb-lg-23 bg-default-1" ref={learnMoreRef}>
        <Container>
          <Row className="justify-content-center">
            <Col md="10" lg="8" xl="7">
              <div className="section-title text-center mb-11 mb-lg-19">
                <h2 className="gr-text-4 mb-6">Build out your demo fast</h2>
                <p className="gr-text-8 px-lg-4">
                  Our app lets you easily build out a video driven demo, with the content you already have available
                </p>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col sm="10" lg="6" className="mb-10 mb-lg-0">
              <div
                className="content-img img-group-2 rounded-10"
                style={{border: '8px solid #161C2D'}}
              >
                <Img
                  className="img-fluid"
                  fluid={data.contentImage.childImageSharp.fluid}
                  alt="Flow Builder"
                />
              </div>
            </Col>
            <Col sm="11" lg="6" xl="5">
              <div className="content-widget-1 pl-lg-9">
                <div className="media single-widget mb-7">
                  <div className="count circle-sm gr-bg-blue-opacity-1 mr-8">
                    <span className="text-primary--light-only gr-text-9">
                      1
                    </span>
                  </div>
                  <div className="media-body">
                    <h3 className="gr-text-7">Create a flow</h3>
                    <p className="gr-text-9">
                      With lots of unique interactions, you can easily build an immersive demo.
                    </p>
                  </div>
                </div>
                <div className="media single-widget mb-7">
                  <div className="count circle-sm gr-bg-blue-opacity-1 mr-8">
                    <span className="text-primary--light-only gr-text-9">
                      2
                    </span>
                  </div>
                  <div className="media-body">
                    <h3 className="gr-text-7">Upload content & arrange</h3>
                    <p className="gr-text-9">
                      Videos, images, and more to showcase your product. We optimize for the viewer.
                    </p>
                  </div>
                </div>
                <div className="media single-widget mb-7">
                  <div className="count circle-sm gr-bg-blue-opacity-1 mr-8">
                    <span className="text-primary--light-only gr-text-9">
                      3
                    </span>
                  </div>
                  <div className="media-body">
                    <h3 className="gr-text-7">Embed your flow</h3>
                    <p className="gr-text-9">
                      With a few lines of Javascript, embed your flow in your own site.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

Content.propTypes = {
  learnMoreRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.elementType })
  ])
}

export default Content;
