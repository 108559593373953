import React, { useState } from "react";
import Axios from "axios"
import dig from 'object-dig';
import Notifications, {notify} from 'react-notify-toast';
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/landing/Hero";
import Features from "../sections/landing/Features";
import UseCases from "../sections/landing/UseCases";
import Fact from "../sections/landing/Fact";
import Content2 from "../sections/landing/Content2";
import SpeedToLead from "../sections/landing/SpeedToLead";
import Pricing from "../sections/landing/Pricing";
import Faq from "../sections/landing/Faq";
import CTA from "../sections/landing/CTA";
import SignupModal from "../components/SignupModal";

const Index = ({ location }) => {
  const learnMoreRef = React.createRef()

  const [showModal, setShowModal] = useState(false);
  const [isSubmittingTrialForm, setIsSubmittingTrialForm] = useState(false);

  const onClickInstantDemo = () => {
    if (window && window.INSTANT_DEMO) {
      window.INSTANT_DEMO.initiate_experiment('51ec55d3-8751-4803-afeb-4a69afc28634')
    }

    if (window !== "undefined" && window.gtag) {
      window.gtag("event", "conversion", { send_to: 'AW-411216111/QZPVCKPc9_0BEO_RisQB' })
    }
  }

  const onScheduleMeeting = () => {
    if (window && window.INSTANT_DEMO) {
      window.INSTANT_DEMO.initiate_experiment('51ec55d3-8751-4803-afeb-4a69afc28634', { start_scheduling: true })
    }

    if (window !== "undefined" && window.gtag) {
      window.gtag("event", "conversion", { send_to: 'AW-411216111/QZPVCKPc9_0BEO_RisQB' })
    }
  }

  const onClickFreeTrial = () => {
    setShowModal(true)
  }

  const onCancelSignupForTrial = () => {
    setShowModal(false)
  }

  const onSubmitFreeTrial = ({name, email}) => {
    const requestPayload = {
      name,
      email
    }

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
    
    setIsSubmittingTrialForm(true)
    Axios.post(`${process.env.GATSBY_INSTANT_DEMO_API_URL}/api/trial_request`, requestPayload, {
      headers: headers
    })
    .then((payload) => {
      notify.show('Someone will be reaching out shortly with your account credentials!', 'success')

      setShowModal(false)
    })
    .catch((payload) => {
      const error = dig(payload, 'response', 'data', 'message') || 'Something went wrong'
      notify.show(error, 'error')
    })
    .finally(() => {
      setIsSubmittingTrialForm(false)
    })
  }

  const { hash } = location
  if (hash == "#pricing") {
    setTimeout(() => {
      const pricingSection = document.getElementById('pricing-section');
      if(pricingSection){
        pricingSection.scrollIntoView({ behavior: 'smooth' })
      }
    }, 500)
  }

  return (
    <>
      <PageWrapper
        headerConfig={{
          isFluid: true,
        }}
      >
        <Notifications options={{zIndex: 2000}} />
        <SignupModal showModal={showModal} onClose={onCancelSignupForTrial} onSubmit={onSubmitFreeTrial} isSubmitting={isSubmittingTrialForm} />
        <Hero onClickInstantDemo={onClickInstantDemo} onClickFreeTrial={onClickFreeTrial} />
        <Features />
        <UseCases />
        <Fact />
        <SpeedToLead />
        <Content2 learnMoreRef={learnMoreRef} />
        <Pricing onClickContactUs={onScheduleMeeting} />
        <Faq />
        <CTA onClickInstantDemo={onClickInstantDemo} />
      </PageWrapper>
    </>
  );
};

export default Index;
