import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby";

import { Container, Row, Col } from "react-bootstrap";

const UseCases = () => {
  const data = useStaticQuery(graphql`
    query {
      contentImage: file(relativePath: { eq: "landing/online-content.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section pt-12 pb-13 pt-lg-21 pb-lg-22 bg-default-2">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col sm="10" lg="5" className="pr-lg-9">
              <div className="section-title content-text mb-13 mb-lg-15">
                <h2 className="title gr-text-4 mb-6">Use Cases</h2>
              </div>
              <div className="content-widget">
                <Row className="mb-n11">
                  <Col
                    md="6"
                    lg="10"
                    data-aos="fade-left"
                    data-aos-duration="1100"
                  >
                    <div className="single-widget mb-11">
                      <h3 className="w-title gr-text-7 mb-4">
                        Sales
                      </h3>
                      <p className="gr-text-9 mb-0">
                        Send your prospect the most unforgettable cold email they’ve ever received
                      </p>
                      <Link
                        to="/sales"
                        role="button"
                        aria-expanded="false"
                        className="btn-link with-icon mt-6 text-purple h6"
                      >
                        Learn More
                        <i class="fas fa-arrow-right gr-text-12"></i>
                      </Link>
                    </div>
                  </Col>
                  <Col
                    md="6"
                    lg="10"
                    data-aos="fade-left"
                    data-aos-duration="1100"
                  >
                    <div className="single-widget mb-11">
                      <h3 className="w-title gr-text-7 mb-4">
                        Pre-Sales
                      </h3>
                      <p className="gr-text-9 mb-0">
                        Never waste a Solutions Consultant’s time again
                      </p>
                      <Link
                        to="/pre-sales"
                        role="button"
                        aria-expanded="false"
                        className="btn-link with-icon mt-6 text-purple h6"
                      >
                        Learn More
                        <i class="fas fa-arrow-right gr-text-12"></i>
                      </Link>
                    </div>
                  </Col>
                  <Col
                    md="6"
                    lg="10"
                    data-aos="fade-left"
                    data-aos-duration="750"
                  >
                    <div className="single-widget mb-11">
                      <h3 className="w-title gr-text-7 mb-4">
                        Marketing
                      </h3>
                      <p className="gr-text-9 mb-0">
                        Give your inbound leads a live product demo right when they ask for it.
                      </p>
                      <Link
                        to="/marketing"
                        role="button"
                        aria-expanded="false"
                        className="btn-link with-icon mt-6 text-purple h6"
                      >
                        Learn More
                        <i class="fas fa-arrow-right gr-text-12"></i>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col sm="10" lg="6" className="offset-lg-1 mt-10 mt-lg-0">
              <div
                className="content-img img-group-1"
              >
                <Img
                  className="img-fluid"
                  fluid={data.contentImage.childImageSharp.fluid}
                  alt="Lead Collection"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default UseCases;
